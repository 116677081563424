// extracted by mini-css-extract-plugin
export var Main = "reader-module--Main--441zv";
export var PaddingBottom = "reader-module--PaddingBottom--ypan0";
export var Footer = "reader-module--Footer--BBGM7";
export var FooterRise = "reader-module--FooterRise--VvsOe";
export var InnerFooter = "reader-module--InnerFooter--XtNAX";
export var Navigation = "reader-module--Navigation--7Wjl6";
export var Prev = "reader-module--Prev--QOdSQ";
export var Next = "reader-module--Next--bR70J";
export var PrevArrow = "reader-module--PrevArrow--8GH6W";
export var NextArrow = "reader-module--NextArrow--RcpCg";