import React, { useContext, useState } from "react";
import Seo from "../components/seo";
import HeadingWithSub from "../components/HeadingWithSub";
import { SizeMe } from "react-sizeme";
import * as classes from "./reader.module.css";
import ParentRouteHandler from "../components/ParentRouteHandler";
import { Document, Page, pdfjs } from "react-pdf";
import { StoreContext } from "../store";
import { StaticImage } from "gatsby-plugin-image";

if (pdfjs) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
}

const PdfReader = ({ location }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isAudio, setIsAudio] = useState(false);

  const { audioDetails } = useContext(StoreContext);

  const { state = {} } = location;

  const { pdf } = state;
  const { origin } = state;
  const { originalPath } = state;

  function onDocumentLoadSuccess({ children, numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  let pdfFile = "";

  if (pdf) pdfFile = pdf;

  React.useEffect(() => {
    if (pdfjs.GlobalWorkerOptions.workerSrc) {
      setIsAvailable(true);
    }

    if (audioDetails.url) {
      setIsAudio(true);
    } else {
      setIsAudio(false);
    }
  });

  return pdf ? (
    <>
      <Seo title="PDF Reader" />

      {origin && originalPath ? (
        <ParentRouteHandler title={origin} path={originalPath} />
      ) : (
        <ParentRouteHandler title="Home" path="/" />
      )}

      <div className="heading">
        <HeadingWithSub
          main={location.state.category}
          subTitle={location.state.title}
        />
      </div>

      {isAvailable ? (
        <SizeMe
          monitorHeight
          refreshRate={128}
          refreshMode={"debounce"}
          render={({ size }) => (
            <div className={isAudio ? classes.PaddingBottom : ""}>
              <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} width={size.width} />
              </Document>
            </div>
          )}
        />
      ) : (
        ""
      )}

      <div className={isAudio ? classes.FooterRise : classes.Footer}>
        <div className={classes.InnerFooter}>
          <div className={classes.Navigation}>
            <div className={classes.Prev}>
              <StaticImage
                className={classes.PrevArrow}
                src="../images/icon-arrow-white.svg"
                alt="back arrow"
              />
              <button
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                Previous
              </button>
            </div>

            <p>
              {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </p>

            <div className={classes.Next}>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                Next
              </button>
              <StaticImage
                className={classes.NextArrow}
                src="../images/icon-arrow-white.svg"
                alt="back arrow"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      {origin && originalPath ? (
        <ParentRouteHandler title={origin} path={originalPath} />
      ) : (
        <ParentRouteHandler title="Home" path="/" />
      )}

      <h2>Coming Soon</h2>
    </>
  );
};

export default PdfReader;
